<template>
  <div class="doc-outter">
    <div class="doc-title">
      <h3>联系我们</h3>
    </div>
    <div class="doc-content">
      <div class="noteCont contact m_t_30">
        <div class="sec">
          <p>网址： www.joy-air.com</p>
          <p>地址： 西安市高新区唐延路35号旺座现代城 A座18、19层</p>
          <p>邮政编码： 710065</p>
          <p>服务与订票热线： 400-868-0000</p>
          <p>建议与投诉： 400-868-0000</p>
          <p>客服邮箱： jrkefu@joy-air.net</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
</style>
